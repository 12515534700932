import React from 'react';
import CardComponent from './CardComponent';
import 'bootstrap/dist/css/bootstrap.min.css';

const Service = () => {
  const cardData = [
    {
      imageUrl: "assets/img/services/s1.jpg",
      title: "Residential Septic Tank Cleaning",
      description: "Ensure your home's septic system is functioning optimally with our thorough cleaning services. Cleaning a sewage tank in a residential home is known as residential septic tank cleaning. The procedure of cleaning a home septic tank begins with an inspection of the tank's inside for signs of damage or blockage.",
      link: "#"
    },
    {
      imageUrl: "assets/img/services/s2.jpg",
      title: "Apartment Septic Tank Cleaning",
      description: "Keep your apartment complex safe and hygienic with our expert septic tank cleaning services.A septic tank is an essential component of any house or company.It is in charge of filtering and breaking down solid waste in order to prevent it from blocking drain fields and seeping into groundwater",
      link: "#"
    },
    {
      imageUrl: "assets/img/services/s3.jpg",
      title: "Hospital Septic Tank Cleaning",
      description: "Maintain a sanitary environment in hospitals with our specialized septic tank cleaning services.The sewage from the hospital is piped and discharged into the septic tank. if a septic tank is unable to regulate the wastewater treatment process, it might result in a problems that are dangerous to hospital patients and visitors.",
      link: "#"
    },
    {
      imageUrl: "assets/img/services/s4.jpg",
      title: "Commercial Building Septic Tank Cleaning",
      description: "Top-tier septic cleaning services for businesses, from tank cleaning to installation and maintenance. When it comes to offering superior septic cleaning services to enterprises, there is no substitute.Our assistants are experts in all septic cleaning services, from tank cleaning to installation and maintenance.",
      link: "#"
    }
  ];

  return (
    <section id="services" className="features" style={{ background: '#0d1e78' }}>
      <div className="container pt-3">
        <div className="section-title">
          <h2 style={{ color: '#fff' }}>Doorstep Service</h2>
        </div>
        <div className="row">
          {cardData.map((card, index) => (
            <div className="col-md-3" key={index}>
              <CardComponent 
                imageUrl={card.imageUrl} 
                title={card.title} 
                description={card.description} 
                link={card.link} 
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
