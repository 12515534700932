
import React from 'react';

function Contact() {
  return (
    <>
      {/* Contact Section - Home Page */}
      <section id="contact" className="contact">
        {/* Section Title */}
        <div className="container section-title">
          <h2>Contact</h2>
          <p></p>
        </div>
        {/* End Section Title */}
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-12">
                  <div className="info-item">
                    <i className="bi bi-geo-alt" />
                    <h3>Address</h3>
                    Opp Hotel Ram Chandra,Old,<br/> Old Gajuwaka, Visakhapatnam,<br/> Andhra Pradesh 530026
                  </div>
                </div>
                {/* End Info Item */}
                <div className="col-md-6">
                  <div className="info-item">
                    <i className="bi bi-envelope" />
                    <h3>Email Us</h3>
                    <p>jaydevseptictankcleanersvizag@gmail.com</p>
                  </div>
                </div>
                {/* End Info Item */}
                <div className="col-md-6">
                  <div className="info-item">
                    <i className="bi bi-telephone" />
                    <h3>Call Us</h3>
                    <p>9848975558</p>
                  </div>
                </div>
                {/* End Info Item */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="100%"
                    height="400"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=17%C2%B041%2707.8%22N%2083%C2%B012%2714.4%22E&t=k&z=17&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                  <style>
                    {`
                      .mapouter {
                        position: relative;
                        text-align: right;
                        height: 400px;
                        width: 100%;
                      }
                      .gmap_canvas {
                        overflow: hidden;
                        background: none !important;
                        height: 500px;
                        width: 100%;
                      }
                    `}
                  </style>
                </div>
              </div>
            </div>
            {/* End Map */}
          </div>
        </div>
      </section>
      {/* End Contact Section */}
    </>
  );
}

export default Contact;
