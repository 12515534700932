import React from 'react';
import cusImage from '../constants/cus.png';

function Sbuttons() {
  // Static data
  const contactNumber = "9848975558";
  const email = "example@example.com";
  const whatsapp= "9848975558";
   

  return (
    <div className="sbuttons">
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="style.css" />
      <a href={`mailto:${email}`} target="_blank" className="sbutton mail" tooltip="Mail">
        <i className="fa fa-envelope" />
      </a>
      <a href={`tel:${contactNumber}`} target="_blank" className="sbutton call" tooltip="Call">
        <i className="fa fa-phone" />
      </a>
      <a
        href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=Hi Jay Dev septic tank cleaning, I want your service`}
        className="sbutton whatsapp"
        target="_blank"
        tooltip="WhatsApp"
      >
        <i className="fab fa-whatsapp" />
      </a>
      <a target="_blank" className="sbutton mainsbutton" tooltip-active="contact">
        <img
          src={cusImage}
          alt="Contact"
          style={{ width: 39, paddingTop: 9 }}
        />
      </a>
    </div>
  );
}

export default Sbuttons;
