import React from 'react';

function Services() {
  return (
    <>
      <section id="service" className="about">
        <div className="container">
          <div className="row align-items-xl-center gy-5">
            <div className="col-xl-5 content" data-aos="fade-up">
              <h2>Our Services</h2> {/* H1 Heading */}
              <h1 style={{ color: '#ffff' }}>Vizag Septic Tank Cleaning</h1> {/* H2 Heading */}
              <p style={{ color: '#ffff' }}>
                Your trusted partner for septic tank cleaning and maintenance in Vizag.
                We offer top-quality services to keep your septic system running smoothly and efficiently.
              </p>
              <a href="tel:9848975558" className="read-more">
                <span>Contact Us</span>
                <i className="bi bi-arrow-right" />
              </a>
            </div>
            <div className="col-xl-7">
              <div className="row gy-4 icon-boxes">
                <div className="col-md-6">
                  <div className="icon-box" data-aos="fade-up" style={{ background: '#e84545', color: '#fff' }}>
                    <img
                      src="assets/img/icon3.png"
                      width={100}
                      alt="Vizag Septic Tank Cleaning"
                      style={{ filter: 'invert(1) brightness(2)' }}
                    />
                    <h3>Septic Tank Cleaning</h3>
                    <p>
                      Regular cleaning of your septic tank is essential to prevent backups and extend the life of your system.
                      Our experts provide thorough and efficient cleaning services to keep your system in top condition.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="icon-box" data-aos="fade-up" style={{ background: '#e84545', color: '#fff' }}>
                    <img
                      src="assets/img/icon2.png"
                      alt="Vizag Septic Tank Maintenance"
                      style={{ filter: 'invert(1) brightness(2)' }}
                    />
                    <h3>Septic Tank Maintenance</h3>
                    <p>
                      Preventive maintenance is key to avoiding costly repairs. We offer comprehensive maintenance services,
                      including inspections and minor repairs, to ensure your system operates effectively.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="icon-box" data-aos="fade-up" style={{ background: '#e84545', color: '#fff' }}>
                    <img
                      src="assets/img/icon1.png"
                      width={100}
                      alt="Emergency Septic Tank Services in Vizag"
                      className="mb-2"
                      style={{ filter: 'invert(1) brightness(2)' }}
                    />
                    <h3>Emergency Services</h3>
                    <p>
                      Septic system emergencies can happen at any time.
                      Our team is available 24/7 to handle any urgent issues and provide prompt, reliable solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
