import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
  };

  const closeMobileNav = () => {
    setIsMobileNavActive(false);
  };

  return (
    <>
      {/* ======= Header ======= */}
      <header id="header" className={`header fixed-top d-flex align-items-center ${isMobileNavActive ? 'mobile-nav-active' : ''}`}>
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center me-auto me-xl-0">
            <img src="assets/img/logo1.jpg" alt="Vizag GVMC Septic Tank Cleaning" width="100%" />
          </Link>
          {/* Nav Menu */}
          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <Link to="/" onClick={closeMobileNav}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={closeMobileNav}>About</Link>
              </li>
              <li>
                <Link to="/services" onClick={closeMobileNav}>Services</Link>
              </li>
              <li>
                <Link to="/Gallery" onClick={closeMobileNav}>Gallery</Link>
              </li>
              <li>
                <Link to="/contact" onClick={closeMobileNav}>Contact</Link>
              </li>
            </ul>
            <i
              className={`mobile-nav-toggle d-xl-none bi ${isMobileNavActive ? 'bi-x' : 'bi-list'}`}
              onClick={toggleMobileNav}
            />
          </nav>
          {/* End Nav Menu */}
        </div>
      </header>
      {/* End Header */}
    </>
  );
}

export default Header;
