import React from 'react';

function About() {
  return (
    <>
      <section id="about" className="features" style={{ background: '#0d1e78' }}>
        <div className="container section-title">
          <h1 style={{ color: '#fff' }}>About Us</h1> {/* H1 Heading */}
          <p style={{ color: '#fff' }}>
            Your trusted partner for septic tank cleaning and maintenance in Vizag.
          </p>
        </div>
        <div className="container">
          <div className="row gy-4 align-items-center features-item">
            <div className="col-lg-5 order-2 order-lg-1" data-aos="fade-up">
              <h1 style={{ color: '#fff' }}>Vizag GVMC Septic Tank Cleaning</h1> {/* H2 Heading */}
              <p style={{ color: '#fff' }}>
                We offer top-quality services to keep your septic system running smoothly and efficiently.
              </p>
              <a href="tel:9848975558" className="btn btn-get-started">
                Contact Us
              </a>
            </div>
            <div className="col-lg-7 order-1 order-lg-2 d-flex align-items-center features-img-bg" data-aos="zoom-out">
              <img src="assets/img/gallery/1.jpg" className="img-fluid" alt="Vizag GVMC Septic Tank Cleaning" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
