import React from 'react';

function WhyUs() {
  return (
    <section id="about" className="features">
      <div className="container">
        <div className="row gy-4 align-items-stretch justify-content-between features-item">
          <div
            className="col-lg-6 d-flex align-items-center features-img-bg"
            data-aos="zoom-out"
          >
            <img src="assets/img/gallery/5.jpg" className="img-fluid" alt="Why Choose Us" />
          </div>
          <div
            className="col-lg-5 d-flex justify-content-center flex-column"
            data-aos="fade-up"
          >
            <h1><strong>Why Choose Us for Septic Tank Cleaning in Vizag?</strong></h1>
            <ul>
              <li>
                <i className="bi bi-check" />
                <>Experienced Professionals: </>Our team consists of highly trained and experienced technicians who are dedicated to providing top-notch service.
              </li>
              <li>
                <i className="bi bi-check" />
                <>Advanced Equipment:</> We use state-of-the-art equipment to ensure thorough cleaning and accurate inspections.
              </li>
              <li>
                <i className="bi bi-check" />
                <>Customer Satisfaction:</> Your satisfaction is our priority. We strive to provide excellent customer service and high-quality results.
              </li>
              <li>
                <i className="bi bi-check" />
                <>Affordable Pricing:</> We offer competitive pricing without compromising on quality.
              </li>
            </ul>
            <a href="tel:9848975558" className="btn btn-get-started align-self-start">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUs;
