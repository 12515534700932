import React,{ useEffect }  from 'react'
import Contact from '../Components/Contact'
import { Helmet } from 'react-helmet';

function ContactPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='mt-5'>
          <Helmet>
        <title>GVMC SEPTIC TANK CLEANERS VIZAG | Septic Tank Cleaning Vizag</title>
        <meta name="description" content="9848975558 GVMC Septic Tank Cleaning vizag  provides top-notch septic tank cleaning services in Vizag. Expert septic tank cleaning and maintenance by Jay Dev. Ensure your septic system's efficiency with our reliable services." />
        <meta name="keywords" content="9848975558 GVMC Septic Tank Cleaning vizag, septic tank cleaning, Jay Dev septic tank cleaning, top septic tank cleaning in Vizag, septic tank cleaning in Vizag, Vishakapatnam septic tank cleaning, septic tank maintenance" />
        <meta property="og:title" content="Top Septic Tank Cleaning in Vizag | Jay Dev Septic Tank Cleaning" />
        <meta property="og:description" content="9848975558 GVMC Septic Tank Cleaning vizag provides top-notch septic tank cleaning services in Vizag. Expert septic tank cleaning and maintenance by Jay Dev. Ensure your septic system's efficiency with our reliable services." />
        <meta property="og:image" content="https://gvmcseptictankcleaningvizag.com/assets/img/gallery/1.jpg" />
        <meta property="og:url" content="https://gvmcseptictankcleaningvizag.com/" />
        <meta name="twitter:card" content="https://gvmcseptictankcleaningvizag.com/assets/img/gallery/1.jpg" />
        <meta name="twitter:title" content="Top Septic Tank Cleaning in Vizag | Jay Dev Septic Tank Cleaning" />
        <meta name="twitter:description" content="9848975558 GVMC Septic Tank Cleaning vizag provides top-notch septic tank cleaning services in Vizag. Expert septic tank cleaning and maintenance by Jay Dev. Ensure your septic system's efficiency with our reliable services." />
        <meta name="twitter:image" content="https://gvmcseptictankcleaningvizag.com/assets/img/gallery/1.jpg" />
      </Helmet>
        <Contact />
    </div>
  )
}

export default ContactPage