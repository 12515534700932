import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Counters() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="stats" className="stats">
      <img src="assets/img/bg2.jpg"  alt="Vizag GVMC Septic Tank Cleaning" data-aos="fade-in" />
      <div
        className="container position-relative"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row gy-4">
          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <CountUp
                start={0}
                end={15}
                duration={1}
                className="purecounter"
                delay={0}
                useEasing
                suffix="+"
              />
              <p>Years of Experience</p>
            </div>
          </div>
          {/* End Stats Item */}
          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <CountUp
                start={0}
                end={5000}
                duration={1}
                className="purecounter"
                delay={0}
                useEasing
                suffix="+"
              />
              <p>Customers</p>
            </div>
          </div>
          {/* End Stats Item */}
          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <CountUp
                start={0}
                end={20}
                duration={1}
                className="purecounter"
                delay={0}
                useEasing
                suffix="+"
              />
              <p>Tankers</p>
            </div>
          </div>
          {/* End Stats Item */}
          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <CountUp
                start={0}
                end={30}
                duration={1}
                className="purecounter"
                delay={0}
                useEasing
                suffix="+"
              />
              <p>Areas Covered</p>
            </div>
          </div>
          {/* End Stats Item */}
        </div>
      </div>
    </section>
  );
}

export default Counters;
