import React from 'react';

function CTA() {
  return (
    <>
      {/* Call-to-action Section - Home Page */}
      <section id="call-to-action" className="call-to-action">
        <img 
          src="assets/img/bg.jpg" 
          alt="Jay Dev Septic Tank Cleaning Service in Vizag" 
        />
        <div className="container">
          <div
            className="row justify-content-center"
            data-aos="fade-up"
          >
            <div className="col-xl-10">
              <div className="text-center">
                <h3>Choose Jay Dev Septic Tank Cleaning in Vizag</h3>
                <p>
                  Looking for reliable septic tank cleaning services in Vizag? 
                  Jay Dev is your trusted partner for septic tank cleaning and maintenance. 
                  We offer top-quality services to keep your septic system running smoothly and efficiently.
                </p>
                <a className="cta-btn" href="tel:9848975558">
                  Call Now for Expert Septic Tank Cleaning in Vizag
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Call-to-action Section */}
    </>
  );
}

export default CTA;
