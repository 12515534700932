import React from 'react'

function Fotter() {
  return (
    <>
    {/* ======= Footer ======= */}
    <footer id="footer" className="footer">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-about">
          <img src="assets/img/logo1.jpg"  alt="Vizag GVMC Septic Tank Cleaning" width="80%" style={{borderRadius:'5px'}} />
            {/* <p>
            Jay Dev septic tank cleaning Your trusted partner for septic tank cleaning and maintenance. 
            We offer top-quality services to keep your septic system running smoothly and efficiently.
            </p> */}
            
          </div>
          <div className="col-lg-2 col-6 footer-links">
            <h4 style={{color:'#ffff'}}>Useful Links</h4>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#about">About us</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>

             
            </ul>
          </div>
          <div className="col-lg-2 col-6 footer-links">
            <h4 style={{color:'#ffff'}}>Our Services</h4>
            <ul>
              <li>
                <a href="#service">Septic Tank Cleaning</a>
              </li>
              <li>
                <a href="#service">Septic Tank Maintenance</a>
              </li>
              <li>
                <a href="#service">Emergency Services</a>
              </li>
             
            </ul>
          </div>
          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
          
            <p className="mt-4">
              <strong>Phone:</strong> <span>9848975558</span>
            </p>
            <p>
              <strong>Email:</strong> <span>jaydevseptictankcleanersvizag@gmail.com</span>
            </p>
          </div>
        </div>
      </div>
    
    </footer>
    {/* End Footer */}
  </>
  
  )
}

export default Fotter