import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const cardStyle = {
  position: 'relative',
  flex: 1,
  background: '#fff',
  padding: '1rem 1rem 1.5rem',
  boxShadow: '0 10px 25px rgba(0,0,0,0.08)',
  borderRadius: '1rem',
  minHeight: '170px',
  transition: 'all ease 0.3s',
  overflow: 'hidden',
  animation: 'fadeInLeft 1.5s backwards'
};

const imgStyle = {
  aspectRatio: '500 / 320',
  width: '100%',
  borderRadius: '12px',
  marginBottom: '15px',
  position: 'relative',
  maxHeight: '320px',
  objectFit: 'cover',
  boxShadow: '0 6px 16px -7px #aaa'
};

const cardBodyStyle = {
  color: '#676767',
  width: '100%',
  padding: '0 0.8rem',
  position: 'relative'
};

const cardFooterStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: '1rem',
  right: '1rem',
  width: 'calc(100% - 2rem)'
};

const linkStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#FFD854',
  color: '#fff',
  textShadow: '0px 1px 5px rgba(0,0,0,0.08)',
  fontSize: '1rem',
  fontWeight: '700',
  textDecoration: 'none',
  width: '56%',
  height: '40px',
  borderTopLeftRadius: '1rem',
  borderBottomRightRadius: '1rem',
  filter: 'brightness(0.98)'
};

const iconStyle = {
  color: 'white',
  fontSize: '1.0rem',
  marginLeft: '0.5rem'
};



const CardComponent = ({ imageUrl, title, description, link }) => {
  return (
    <div style={cardStyle} className="card mb-3" data-aos="fade-up">
      <img 
        src={imageUrl} 
        alt={title} 
        title="card image"
        style={imgStyle}
      />
      <div style={cardBodyStyle} className="card-body">
        <h1 className="title-card text-center">{title}</h1>
        <p style={{textAlign: 'justify'}}>{description}</p>
      </div>
     
      <div className="text-center">
  <a href="tel:9848975558" className="btn btn- d-flex align-items-center justify-content-center" style={{ textDecoration: 'none', background:'#e84545',color:'#fff' }}>
    <FontAwesomeIcon icon={faPhoneAlt} style={iconStyle} />
    <strong className="mx-2">Call</strong>
  </a>
</div>

    </div>
  );
};

export default CardComponent;
