import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

// Import Swiper modules directly
import { Pagination, Autoplay } from 'swiper/modules';

function Testimonials() {
  return (
    <section id="testimonials" className="testimonials">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 info" data-aos="fade-up">
            <h3 style={{ color: '#ffff' }}>Testimonials</h3>
            <p style={{ color: '#ffff' }}>
              <strong style={{ color: '#ffff' }}>Jay Dev Septic Tank Cleaning</strong> is your go-to provider for septic tank cleaning services in Vizag. Our clients value our top-quality service and expertise in maintaining septic systems efficiently.
            </p>
          </div>
          <div className="col-lg-7">
            <Swiper
              modules={[Pagination, Autoplay]} // Use modules here
              pagination={{ clickable: true }}
              spaceBetween={30}
              slidesPerView={1}
              autoplay={{ delay: 2000, disableOnInteraction: false }} // 2 seconds delay
              style={{ borderRadius: '5px' }}
            >
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="d-flex">
                    <img
                      src="assets/img/cus.png"
                      className="testimonial-img flex-shrink-0"
                      alt="Jay Dev Septic Tank Cleaning customer testimonial"
                    />
                    <div>
                      <h3>Customer 1</h3>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left" />
                    <span>
                      I couldn't be happier with the service I received from Jay Dev Septic Tank Cleaning in Vizag. They were prompt, professional, and did an excellent job cleaning our septic tank. Highly recommend their services for anyone needing top-notch septic tank cleaning.
                    </span>
                    <i className="bi bi-quote quote-icon-right" />
                  </p>
                </div>
              </SwiperSlide>
              {/* Add more SwiperSlides as needed */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="d-flex">
                    <img
                      src="assets/img/cus.png"
                      className="testimonial-img flex-shrink-0"
                      alt="Jay Dev Septic Tank Cleaning customer testimonial"
                    />
                    <div>
                      <h3>Customer 2</h3>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left" />
                    <span>
                      Jay Dev Septic Tank Cleaning provided exceptional service in Vizag. Their team was knowledgeable and offered great tips for maintaining our septic system. We'll definitely call them again for future septic tank cleaning needs.
                    </span>
                    <i className="bi bi-quote quote-icon-right" />
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
